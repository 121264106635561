import React, { useState, useEffect, useRef } from 'react';
import WideImpactCounter from "./WideImpactCounter";
import { formatDistanceUnitCapitilizeFirstLetter, formatWeightUnit, getImperialDistanceUnit, formatWeightUnitCapitilizeFirstLetter } from "../../util/unitConverter";
import * as alias from "./ImpactAliases";
import '../../../common/css/global.css';

import * as converter from "../../util/converterUtils";
import ReconnectingWebSocket from 'reconnecting-websocket';


//Could make these lazily loaded somehow to improve performance

import './merchant-config/default-dashboard-impact.css'

const LiveImpacts = ({carbonImpact, setCarbonImpact, impact, merchantCode, merchantConfig, showFrenchTranslation, Horizontal, Compressed}) => {
    const [unitTypeWeight, setUnitTypeWeight] = useState(null);
    const [numContributions, setNumContributions] = useState(null);
    const [numActiveMerchants, setNumActiveMerchants] = useState(null);
    const [numContributors, setNumContributors] = useState(null);

    const [passengerDistance, setPassengerDistance] = useState(null);
    const [numPassengers, setNumPassengers] = useState(null);

    const [moneyImpact, setMoneyImpact] = useState(null);
    const [carbonProgressImpact, setCarbonProgressImpact] = useState(null);
    const [lunchesProgressImpact, setLunchesProgressImpact] = useState(null);
    const [productsProgressImpact, setProductsProgressImpact] = useState(null);

    const socket = useRef(null);

    const setupWebsocket = () =>{
        if(!socket.current) {
            socket.current = new ReconnectingWebSocket(`${process.env.WEBSOCKETS_API_URL}/impact-live-feed?merchantCode=${merchantCode}&ref=${encodeURIComponent(process.env.API_KEY)}`);

            socket.current.onopen = () => console.log("ws opened");
            socket.current.onerror = (e) => console.log("ws error:" + e);

            socket.current.onmessage = e => {
                const messageObject = JSON.parse(e.data);
                setNumContributions(prev => prev + 1);
                setCarbonImpact(prev => prev + messageObject.co2Quantity);
            };
        }
    }

    const optionallyRenderImpact = (statAlias, title,unit,value, subText) => {
        return merchantConfig?.stats?.find(c => c.toLowerCase() === statAlias.toLowerCase() ) &&
         <WideImpactCounter 
         Compressed={Compressed}
         horizontal={Horizontal}
            title={title.replace(`$unit`, unit)}
            number={value}
                subText={subText ? subText : null} />
    }

    useEffect(() => {
        if(impact){
            setCarbonProgressImpact(impact.impactProgress?.carbonOffset?.currentCount);
            setLunchesProgressImpact(impact.impactProgress?.lunches?.currentCount)
            setProductsProgressImpact(impact.impactProgress?.products?.currentCount)
            setCarbonImpact(impact.carbonOffsetImpact?.value);
            setUnitTypeWeight((impact.carbonOffsetImpact?.unit).toLowerCase());
            setMoneyImpact(impact?.moneyImpact?.value);
            setNumContributions(impact.numberOfContributions?.value);
            setNumActiveMerchants(impact.numberOfMerchants?.value);
            setNumContributors(impact.numberOfContributors?.value);
            setPassengerDistance(impact?.flightsImpact?.passengerDistance)
            setNumPassengers(impact?.flightsImpact?.numberOfPassengers.value)
            setupWebsocket();
        }

    }, [impact]);

    return(<>
            <div className={`${Compressed === 'true' ? '' : ' h-100vh impact-padding-bottom scroll-y impact-counters-container'} row m-0 `}>
                {optionallyRenderImpact(alias.impact.products_progress.name, alias.impact.products_progress.label, null, productsProgressImpact )}
                {optionallyRenderImpact(alias.impact.wine_progress.name, alias.impact.wine_progress.label, null, carbonProgressImpact )}
                {optionallyRenderImpact(alias.impact.trees_progress.name, alias.impact.trees_progress.label, null, carbonImpact && converter.carbonWeightToTrees(carbonProgressImpact, unitTypeWeight) )}
                {optionallyRenderImpact(alias.impact.co2Quantity_progress.name, alias.impact.co2Quantity_progress.label, impact?.carbonOffsetImpact?.unit && formatWeightUnitCapitilizeFirstLetter(formatWeightUnit(impact.carbonOffsetImpact.unit)) || '--', carbonProgressImpact )}
                {optionallyRenderImpact(alias.impact.lunches_progress.name, alias.impact.lunches_progress.label, null, lunchesProgressImpact )}

                {optionallyRenderImpact(alias.impact.airDistance.name, alias.impact.airDistance.label ,passengerDistance?.unit && formatDistanceUnitCapitilizeFirstLetter(passengerDistance.unit) || '--', passengerDistance?.value, showFrenchTranslation && alias.impact.airDistance.frenchText)}
                {optionallyRenderImpact(alias.impact.lunches.name, alias.impact.lunches.label,null, carbonImpact && converter.carbonWeightToLunches(carbonImpact, unitTypeWeight))}
                {optionallyRenderImpact(alias.impact.nights.name, alias.impact.nights.label,null, carbonImpact && converter.carbonWeightToNights(carbonImpact, unitTypeWeight))}
                {optionallyRenderImpact(alias.impact.distance.name, alias.impact.distance.label, getImperialDistanceUnit(unitTypeWeight), carbonImpact && unitTypeWeight === 'lb' ? converter.carbonPoundsToMiles(carbonImpact) : converter.carbonKilogramsToKilometers(carbonImpact))}
                {optionallyRenderImpact(alias.impact.drivers.name, alias.impact.drivers.label,null, numContributors )}
                {optionallyRenderImpact(alias.impact.customers.name, alias.impact.customers.label,null, numContributions )}
                {optionallyRenderImpact(alias.impact.travellers.name, alias.impact.travellers.label,null, numPassengers, showFrenchTranslation && alias.impact.travellers.frenchText)}
                {optionallyRenderImpact(alias.impact.shoppers.name, alias.impact.shoppers.label, null, numContributions)}
                {optionallyRenderImpact(alias.impact.co2Quantity.name, alias.impact.co2Quantity.label, impact?.carbonOffsetImpact?.unit && formatWeightUnitCapitilizeFirstLetter(formatWeightUnit(impact.carbonOffsetImpact.unit)) || '--', carbonImpact, showFrenchTranslation && alias.impact.co2Quantity.frenchText.replace(`$unit`, impact?.carbonOffsetImpact?.unit && formatWeightUnitCapitilizeFirstLetter(formatWeightUnit(impact.carbonOffsetImpact.unit)) || '--'))}
                {optionallyRenderImpact(alias.impact.swipes.name, alias.impact.swipes.label, null, numContributions)}
                {optionallyRenderImpact(alias.impact.transactions.name, alias.impact.transactions.label,null, numContributions)}
                {optionallyRenderImpact(alias.impact.trees.name, alias.impact.trees.label,null, carbonImpact && converter.carbonWeightToTrees(carbonImpact, unitTypeWeight), showFrenchTranslation && alias.impact.trees.frenchText)}
                {optionallyRenderImpact(alias.impact.merchants.name, alias.impact.merchants.label,null, numActiveMerchants)}
                {optionallyRenderImpact(alias.impact.nauticalMiles.name, alias.impact.nauticalMiles.label,null, numActiveMerchants)}
                {optionallyRenderImpact(alias.impact.boaties.name, alias.impact.boaties.label,null, numContributions )}
                {optionallyRenderImpact(alias.impact.kwh_nz.name, alias.impact.kwh_nz.label,null,carbonImpact && converter.get_Kwh_NZ_FromCo2Amount(carbonImpact, unitTypeWeight)  )}
                {optionallyRenderImpact(alias.impact.communityContributions.name, alias.impact.communityContributions.label, impact?.moneyImpact?.currency || '--', moneyImpact )}
            </div>
        </>
    );
}

export default LiveImpacts