// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../round-theory/media/Rt.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.round-theory-style .color-primary{\n    color: #96D096;\n}\n\n.round-theory-style .background-color-primary{\n    background-color: #2aa43c;\n}\n\n.round-theory-style .logo-image {\n     background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.round-theory-style .impact-footer{\n    background-color: #2aa43c;\n}\n\n.round-theory-style .wide-impact-counter {\n    color:#fff;\n    background-color: #96D096;\n}\n\n", "",{"version":3,"sources":["webpack://src/common/components/impact/merchant-config/round-theory.css"],"names":[],"mappings":";AACA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;KACK,yDAA8D;AACnE;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,yBAAyB;AAC7B","sourcesContent":["\n.round-theory-style .color-primary{\n    color: #96D096;\n}\n\n.round-theory-style .background-color-primary{\n    background-color: #2aa43c;\n}\n\n.round-theory-style .logo-image {\n     background-image: url('../../../../round-theory/media/Rt.svg');\n}\n\n.round-theory-style .impact-footer{\n    background-color: #2aa43c;\n}\n\n.round-theory-style .wide-impact-counter {\n    color:#fff;\n    background-color: #96D096;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
