// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../aa-smart-fuel/media/aasf-logo-wide.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aasmartfuel-style .color-primary{\n    color: #FFD400;\n}\n\n.aasmartfuel-style .background-color-primary{\n    background-color: #FFD400;\n}\n\n.aasmartfuel-style .logo-image {\n     background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.aasmartfuel-style .impact-footer{\n    background-color: #eb6c0f;\n}\n\n.aasmartfuel-style .wide-impact-counter{\n    background-color: #FFD400;\n}", "",{"version":3,"sources":["webpack://src/common/components/impact/merchant-config/aasmartfuel.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;KACK,yDAA2E;AAChF;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".aasmartfuel-style .color-primary{\n    color: #FFD400;\n}\n\n.aasmartfuel-style .background-color-primary{\n    background-color: #FFD400;\n}\n\n.aasmartfuel-style .logo-image {\n     background-image: url('../../../../aa-smart-fuel/media/aasf-logo-wide.png');\n}\n\n.aasmartfuel-style .impact-footer{\n    background-color: #eb6c0f;\n}\n\n.aasmartfuel-style .wide-impact-counter{\n    background-color: #FFD400;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
