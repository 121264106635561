export const impact = {
    nights: { name: 'nights', label: 'Nights offset' },
    lunches: { name: 'lunches', label: 'Climate Friendly Lunches' },
    carbonAmount: {name: 'carbonAmount', label: '$unit of carbon offset' },
    airDistance: { name: 'airDistance', label: `Air $unit Made Climate Friendly`, frenchText: `Voyage neutre par km parcouru en avion`},
    distance: { name: 'distance', label: '$unit Made Climate Friendly' },
    drivers: { name: 'drivers', label: 'Climate Friendly Drivers' },
    customers: { name: 'customers', label: 'Climate Friendly Customers' },
    travellers: { name: 'travellers', label: 'Climate Friendly Travellers', frenchText: `Nombre de passagers eco friendly` },
    co2Quantity: { name: 'co2Quantity', label: '$unit of CO2 Offset', frenchText: `$unit de CO2 compensés` },
    swipes: { name: 'swipes', label: 'AA Smartfuel Rewards Converted' },
    transactions: { name: 'transactions', label: 'Transactions' },
    trees: { name: 'trees', label: 'Tree Growth Years', frenchText: `Années de croissance des arbres` },
    merchants: { name: 'merchants', label: 'Merchants' },
    nauticalMiles: { name: 'nauticalMiles', label: 'Nautical Miles Offset' },
    boaties: { name: 'boaties', label: 'Boaties Taking Action' },
    shoppers: { name: 'shoppers', label: 'Climate Friendly Shoppers' },
    kwh_nz: { name: 'kwh_nz', label: 'kWh of electricity' },

    wine_progress: { name: 'wine_progress', label: 'Climate Friendly Glasses of Wine' },
    products_progress: { name: 'products_progress', label: 'Climate Friendly Products' },
    lunches_progress: { name: 'lunches_progress', label: 'Climate Friendly Lunches' },
    co2Quantity_progress: { name: 'co2Quantity_progress', label: '$unit of CO2 Offset' },
    trees_progress: { name: 'trees_progress', label: 'Tree Growth Years' },
    communityContributions: { name: 'communityContributions', label: '$unit Community Contributions' },
}