import { convertToKG, convertToMilesFromKm, convertToTonne } from "./unitConverter";

const carbonKilogramsToKilometers = impact => impact / 0.156; // using petrol - small 
const carbonPoundsToMiles = impact => {
    //convert lb to kg
    const impactInKG = convertToKG(impact, 'lb');
    const kilometers = carbonKilogramsToKilometers(impactInKG);
    //convert km to miles
    return convertToMilesFromKm(kilometers).value;
}
const carbonKilogramsToTrees = impact => Math.trunc(impact / 20);
const carbonWeightToTrees = (impact, unit) => {
    let impactInKG = impact;
    if (unit.toLowerCase() === 'lb')
        impactInKG = convertToKG(impact, unit);
    return carbonKilogramsToTrees(impactInKG);
}

const carbonWeightToNumberOfItems = (impact, unit, kgsPerItem) => {
    let impactInKG = impact;
    if (unit?.toLowerCase() === 'lb')
        impactInKG = convertToKG(impact, unit);
    return impactInKG / kgsPerItem;
}

const carbonKilogramsToNights = impact => impact / 18.61;
const carbonWeightToNights = (impact, unit) => {
    let impactInKG = impact;
    if (unit.toLowerCase() === 'lb')
        impactInKG = convertToKG(impact, unit);
    return carbonKilogramsToNights(impactInKG);
}
const carbonKilogramsToLunches = impact => impact / 5; // not sure what the conversion rate is
const carbonWeightToLunches = (impact, unit) => {
    let impactInKG = impact;
    if (unit.toLowerCase() === 'lb')
        impactInKG = convertToKG(impact, unit);
    return carbonKilogramsToLunches(impactInKG);
}


const getKilometersFromCo2Amount = (impact) => {
    if (!impact?.value) return 0;

    const tonnesOfCo2 = convertToTonne(impact.value, impact.unit);
    const kilometers = tonnesOfCo2 / 0.000156; //small - petrol multiplier
    return kilometers.toFixed(0);
};

const get_Kwh_NZ_FromCo2Amount = (impact, unit) => {
    let impactInKG = impact;
    if (unit.toLowerCase() === 'lb')
        impactInKG = convertToKG(impact, unit);

    return impactInKG / 0.1097; //from TomR-https://carbonclickworkspace.slack.com/archives/CJM9PS26B/p1632793338019800?thread_ts=1632793055.019100&cid=CJM9PS26B
}

export {carbonKilogramsToKilometers, carbonKilogramsToTrees, carbonKilogramsToNights, carbonKilogramsToLunches,
    carbonPoundsToMiles, carbonWeightToLunches, carbonWeightToNights, carbonWeightToTrees, carbonWeightToNumberOfItems,getKilometersFromCo2Amount,get_Kwh_NZ_FromCo2Amount}