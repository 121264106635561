import React, { useState, useEffect } from 'react';
import * as impact from "../../api/impact";
import * as alias from "./ImpactAliases";
import { useLocation } from "react-router-dom";
import ccLogo from '../../media/cc_logo_white.svg'
import ccLogo_color from '../../media/cc_logo_color.svg'
import ccIcon from '../../media/cc-icon-white.svg'
import ccIcon_color from '../../media/cc-icon.svg'
import '../../css/global.css';
import { getConfigByMerchantCode, MERCHANT_BASKET } from './merchant-config/merchantConfig';
import LiveImpacts from './LiveImpacts'
import Project from "../../components/Projects/ProjectV2";
import * as project from "../../../common/api/projects";
import '../../../common/css/ImpactPage.css'

import './merchant-config/aasmartfuel.css';
import './merchant-config/air-tahitinui.css';
import './merchant-config/etihad.css';
import './merchant-config/airport-doha.css';
import './merchant-config/one-hotels.css';
import './merchant-config/sapphire-traveller.css';
import './merchant-config/radiance.css';
import './merchant-config/round-theory.css';
import './merchant-config/st-james-quarter.css';
import './merchant-config/intern-abroad-hq.css';
import './merchant-config/volunteer-hq.css';
import './merchant-config/contact-energy.css';
import 'url-search-params-polyfill';

const isStatsEqual = (merchantStats, queryStats) => {
    if(Array.isArray(merchantStats) && Array.isArray(queryStats)) {
        if(merchantStats.length === queryStats.length) {
            merchantStats.forEach(v => {
                if(!queryStats.includes(v)) {
                    return false
                }
            });
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const ImpactDashboard = ({ merchantCode, showFrenchTranslation }) => {
    const [merchantConfig, setMerchantConfig] = useState();
    const [fetchedImpact, setFetchedImpact] = useState(null);
    const [projectsByMerchantBasket, setProjectsByMerchantBasket] = useState();
    const [updatedTimeout, setUpdatedTimeout] = useState(null);
    const [currentlyDisplayedCarbonImpact, setCurrentlyDisplayedCarbonImpact] = useState(null);
    const [countImpactIgnoreRequests, setCountImpactIgnoreRequests] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const banner = document.getElementById("cookie-notice-banner");
        if(banner != null){
            banner.remove();
        }
    }, [])

    const params = new URLSearchParams(location.search);
    let stats = params.get("stats");
    stats = stats?.split(',');
    let locale = params.get("locale");
    const showProjects = params.get("projects");
    const horizontal = params.get("horizontal");
    //const showFooter = params.get("footer");
    const compressed = params.get("compressed");
    const TIMEOUT_VALUE = 30000; //30secs

    // cache time information: for all impact - 120000ms, for merchant impact - 20000ms
    const IMPACT_ALL_CACHE_TIME = 120000;
    const IMPACT_MERCHANT_CACHE_TIME = 20000;
    const IMPACT_ALL_IGNORE_MAX_COUNT =  Math.ceil(IMPACT_ALL_CACHE_TIME/TIMEOUT_VALUE);
    const IMPACT_MERCHANT_IGNORE_MAX_COUNT = Math.ceil(IMPACT_MERCHANT_CACHE_TIME/TIMEOUT_VALUE);

    useEffect(() => {
        if(merchantConfig){
            if(!fetchedImpact) {
                getImpactFromAPI(merchantConfig);
            }
            const interval = setInterval(() => {
                getImpactFromAPI(merchantConfig);
            }, TIMEOUT_VALUE);
            return () => clearInterval(interval);
        }
    }, [merchantConfig, currentlyDisplayedCarbonImpact, countImpactIgnoreRequests]);

    useEffect(() => {
        let merchant = getConfigByMerchantCode(merchantCode);
        setMerchantConfig(merchant);
    }, [merchantCode]);

    useEffect(() => {
        if (merchantConfig?.showProjects?.[0] === MERCHANT_BASKET){
            project.projects(null, merchantCode, null)
                .then(response => response.json())
                .then(body => setProjectsByMerchantBasket(body))
                .catch(error => {
                    console.error(error);
                    setProjectsByMerchantBasket(error);
                })
        }
           
    }, [merchantConfig])

    const getImpactFromAPI = (merchantConfig) => {
        if (merchantCode !== 'ALL') {
            if (stats) {
                if(!isStatsEqual(merchantConfig?.stats, stats)) {
                    setMerchantConfig({...merchantConfig, stats})
                }
            }
            impact.merchants(merchantCode, locale ? locale : merchantConfig.locale, (stats ? stats : merchantConfig?.stats).indexOf(alias.impact.airDistance.name) >= 0 ? ['flightsImpact'] : null)
                .then(response => response.json())
                .then(body => {
                    if (body.carbonOffsetImpact?.value < currentlyDisplayedCarbonImpact && countImpactIgnoreRequests < IMPACT_MERCHANT_IGNORE_MAX_COUNT) {
                        setCountImpactIgnoreRequests(prev => prev + 1);
                    } else {
                        setFetchedImpact(body);
                        setCountImpactIgnoreRequests(0);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setFetchedImpact(error)
                })
        } else {
            if (stats) {
                if(!isStatsEqual(merchantConfig?.stats, stats)) {
                    setMerchantConfig({...merchantConfig, stats})
                }
            }
            impact.all(locale ? locale : merchantConfig.locale)
                .then(response => response.json())
                .then(body => {
                    if (body.carbonOffsetImpact?.value < currentlyDisplayedCarbonImpact && countImpactIgnoreRequests < IMPACT_ALL_IGNORE_MAX_COUNT) {
                        setCountImpactIgnoreRequests(prev => prev + 1);
                    } else {
                        setFetchedImpact(body)
                        setCountImpactIgnoreRequests(0);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setFetchedImpact(error)
                })
        }
    }

    return (
        <>
            { fetchedImpact?.carbonOffsetImpact ?
                <div className={`${merchantConfig?.topLevelClassNames} ${compressed === 'true' ? 'compressed' : ''}`}>
                    {compressed === 'true' ?
                        <>
                            <div className={'impacts-section noscroll p-3 scroll-y styled-scroll vertical-align-parent'}>
                                <div className="container-fluid mw-1200px text-center vertical-align">
                                    <h4 className="font-bold mt-0 mb-3">Together We Are Fighting Climate Change</h4>
                                    <LiveImpacts carbonImpact={currentlyDisplayedCarbonImpact} setCarbonImpact={setCurrentlyDisplayedCarbonImpact} Compressed={compressed} impact={fetchedImpact} merchantConfig={merchantConfig} merchantCode={merchantCode}
                                        showFrenchTranslation={showFrenchTranslation} Horizontal={horizontal} />
                                    <div className="d-flex py-3">
                                        <div className="d-flex center flex-row">
                                            <small className="powered-by mr-3 font-bold">POWERED BY</small>
                                            <a href="https://www.carbonclick.com/" target="_blank">
                                                <img className="img-white" src={ccLogo} alt="CarbonClick" height={30} />
                                                <img className="img-color" src={ccLogo_color} alt="CarbonClick" height={30} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row m-0 position-relative">
                                {showProjects === 'false' ? null :
                                    <div className={`${horizontal === 'true' ? 'col-md-3 col-sm-12' : 'col-6'} p-0`}>
                                        {merchantConfig && <Project impact={projectsByMerchantBasket ? { projects: projectsByMerchantBasket} : fetchedImpact} merchantCode={merchantCode} />}
                                    </div>
                                }
                                <div className={`${showProjects === 'false' ? 'col-12' : `${horizontal === 'true' ? 'col-sm-12 col-md-9' : 'col-6'}`} p-0 impacts-section`}>
                                    <LiveImpacts carbonImpact={currentlyDisplayedCarbonImpact} setCarbonImpact={setCurrentlyDisplayedCarbonImpact} impact={fetchedImpact} merchantConfig={merchantConfig} merchantCode={merchantCode}
                                        showFrenchTranslation={showFrenchTranslation} Horizontal={horizontal} />
                                </div>

                                <footer className="impact-footer w-100">
                                    <div className="img-contain h-64px w-120px logo-image"></div>
                                    <div className="right flex-row">
                                        <small className="powered-by mr-3 mobile-hide">POWERED BY</small>

                                        <a href="https://www.carbonclick.com/" target="_blank">
                                            <img className="mobile-hide img-white" src={ccLogo} alt="CarbonClick" height={30} />
                                            <img className="mobile-hide img-color" src={ccLogo_color} alt="CarbonClick" height={30} />
                                        </a>
                                        <div className="mobile-show">
                                            <a href="https://www.carbonclick.com/" target="_blank">
                                                <img src={ccIcon} className="img-white" alt="CarbonClick" height={30} />
                                                <img src={ccIcon_color} className="img-color" alt="CarbonClick" height={30} />
                                            </a>
                                        </div>
                                    </div>
                                </footer>
                            </div>
                        </>
                    }

                </div> : fetchedImpact?.errors ?
                    <div style={{ height: '100px', width: '100%', display: 'grid' }}>
                        <div style={{ margin: 'auto' }}>
                            <pre>{`Could not retrieve merchant impact, please try again later.`}</pre>
                        </div>
                    </div> :

                    <div style={{ height: '100px', width: '100%', display: 'grid' }}>
                        <div style={{ margin: 'auto' }}>
                            <pre>Loading impact...</pre>
                        </div>
                    </div>
            }
        </>
    );
};

export default ImpactDashboard;