import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../css/global.css';
import '../../css/ImpactPage.css';
import Merchant_Slides from "./Merchant-Slides.js";

const ProjectV2 = ({ impact, merchantCode }) => {
    return (
            <article className="project-carousel impact-padding-bottom">
                {impact?.projects?.map ? impact?.projects?.length > 1 ? (
                    <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={7000}>

{/* merchantCode && merchantCode == "AASMARTFUEL" && <Merchant_Slides merchantCode={merchantCode} /> */}

                        {impact?.projects?.map(p => (
                            <div key={p.name} className="project-carousel-slide position-relative">
                                <div className="project-img vertical-align-parent">
                                    <div className="vertical-align">
                                        <img className="mw-100" src={p.imageUrl || p.thumbnailImageUrl} />
                                    </div>
                                </div>
                                <div className="position-absolute w-100 bottom-abs py-5 px-3 bg-black-05">
                                    <h4>{p.name}</h4>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                ) : (<div className="project-carousel-slide position-relative">
                    <div className="project-img vertical-align-parent">
                        <div className="vertical-align">
                            <img className="mw-100" src={impact?.projects[0]?.imageUrl || impact?.projects[0]?.thumbnailImageUrl} />
                        </div>
                    </div>
                    <div className="position-absolute w-100 bottom-abs py-5 px-3 bg-black-05">
                        <h4>{impact?.projects[0]?.name}</h4>
                    </div>
                </div>) : impact?.error ? (
                    <div className="d-grid h-100p">
                        <span className="m-auto">Error occurred while loading projects.</span>
                    </div>
                ) : (
                            <div className="d-grid h-100p">
                                <span className="m-auto">Loading...</span>
                            </div>
                        )}
            </article>
    );
};

export default ProjectV2;