import React, { useState, useEffect } from 'react';
import Accordian from '../../common/components/Accordian/Accordian.js'
import facebook from "../../common/media/facebook.svg";
import instagram from "../../common/media/instagram.svg";
import twitter from "../../common/media/twitter.svg";
import { formatNumber } from "../../common/util/numberFormatter";
import { getFlightSuffix } from "../../common/util/unitConverter";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProjectsList from "../../common/components/Projects/ProjectsList";
import { Carousel } from 'react-responsive-carousel';
import { projects as getProjects } from '../../common/api/projects'
import Popover from "../../common/components/popover/popover.js";
import logo from "../../common/media/cc_logo_white.svg";
import FlightCalculator from "../../common/components/offset/FlightCalculatorV2";
import { footerLinks } from '../../common/util/uiConfigs/footerConfiguration.js';
import {trackAdditionalPageView} from "../../common/util/ccAnalyticsV2";

//IMPORTANT - THIS IS USED BY EMA AND STN TOO
const MagEmaStnLandingPage = ({ merchantCode, fetchedImpact, merchantLogo, quoteData }) => {

    const [projects, setProjects] = useState();

    const passengerDistance = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact.flightsImpact?.passengerDistance?.value))
    const passengerDistanceUnit = fetchedImpact && !fetchedImpact.error && getFlightSuffix(fetchedImpact.flightsImpact?.passengerDistance?.unit.toLowerCase());
    const numberOfPassengers = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.flightsImpact?.numberOfPassengers.value);
    const carbonOffsetImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact.carbonOffsetImpact?.value));
    const carbonOffsetImpactUnit = fetchedImpact && !fetchedImpact.error && fetchedImpact.carbonOffsetImpact?.unit.toLowerCase()
    const treeGrowthYears = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.treeGrowthYears?.value);

    const treeMetric = treeGrowthYears && <span className="translate-dynamic"><span>An average tree captures about 20kg of carbon dioxide per year, and about 1 tonne over its lifetime. By funding projects that offset carbon emissions, we are having the same impact as</span> <span className="no-translate"> {treeGrowthYears}</span> <span>trees have in one year.</span></span>

    useEffect(() => {
        getProjects(null, merchantCode)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(setProjects);
        //trackAdditionalPageView('/',['aviation','customer'])
    }, []);

    //IMPORTANT - THIS IS USED BY EMA AND STN TOO
    return (
        <main>
            <header className='w-100 hero-img'>
                <div className='gradient-figure-1'/>
                <div className='gradient-figure-2'/>
                <section className='header-content'>
                    <img className='header-logo' src={merchantLogo} alt='MAG Manchester Airport'/>
                    <h2 className='color-white'><b>Travelling climate friendly has never been easier</b></h2>
                    <h5 className='mt-4 mb-4 color-white'>Climate Friendly Traveller helps you reduce the<br/>environmental impact of travel</h5>
                    <a className="no-color-btn btn-primary btn-lg font-bold mb-3" href="#FlightCalculator">Offset My Flight</a>
                    <div className="d-flex mt-5 pb-4">
                        <p className="mr-2 mt-0 color-white">Powered by</p>
                        <div>
                            <a target="_blank" href="https://www.carbonclick.com/"><img style={{ height: 26 }} src={logo} alt='CarbonClick'/></a>
                        </div>
                    </div>
                </section>
            </header>
            <article id="content" className="py-6 mobile-py-3 mobile-pb-6">
                <div className="container text-center">
                    <section className="p-6 mobile-p-2 pt-5 center mw-600px background-image-logo">
                        <h3 className="font-bold mb-4 mt-3">Travel Climate Friendly</h3>
                        <div>Climate Friendly Traveller helps you reduce the environmental impact of your trip with verified and traceable carbon offsets that make a real difference. Together we are fighting climate change.</div>
                    </section>
                    {false && <div className="pt-4 pb-6">
                        <div className="flex-column px-3 ">
                            <h4 className='font-bold'>OUR IMPACT</h4>
                            <h2 className='font-bold' style={{borderTop: "6px #0B9CA0 solid"}}>{passengerDistance ||
                                <span>&nbsp;</span>}</h2>
                            <h5 className="font-bold">Carbon Neutral Air {passengerDistanceUnit}</h5>
                        </div>

                        <div className="center pt-4">
                            <div className="row text-align-start m-0">

                                <div className="col-4 d-grid">
                                    <div className="center my-3  shadow w-100">
                                        <div className="img-cover h-240px impact-community w-100">
                                        </div>
                                        <div className="my-3">
                                            <h2 className={'font-bold '}>{numberOfPassengers}</h2>
                                            <span>Climate Friendly Travellers</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 d-grid">
                                    <div className="center my-3 shadow w-100">
                                        <div className="img-cover h-240px impact-CO2 w-100">

                                        </div>
                                        <div className="my-3">
                                            <h2 className={'font-bold'}>{carbonOffsetImpact}</h2>
                                            <span>{carbonOffsetImpactUnit} of CO<sub>2</sub> Offset</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 d-grid">
                                    <div className="center my-3 shadow w-100">
                                        <div className="img-cover h-240px impact-trees w-100">

                                        </div>
                                        <div className="my-3">
                                            <h2 className={'font-bold'}>{treeGrowthYears}</h2>
                                            <span><span className="mr-2">Tree Growth Years</span>
                                                <Popover content={treeMetric}>
                                                    <svg style={{marginBottom: -5}} xmlns="http://www.w3.org/2000/svg"
                                                         height="20" viewBox="0 0 24 24" width="20">
                                                        <path d="M0 0h24v24H0z" fill="none"/>
                                                        <path fill="currentColor"
                                                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/>
                                                    </svg>
                                                </Popover>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}


                </div>
            </article>
            <div className="bg-secondary py-5 mobile-p-0" id="FlightCalculator">
                <div className="container mobile-p-0 ">
                    <div className="center mag-flight-calculator-wrapper">
                        <FlightCalculator merchantCode={merchantCode} />
                    </div>
                </div>
            </div>
            <div className="bg-tertiary">
                <div className="container text-center pt-5 pb-1" >
                        <div className='flex-column py-6' style={{color: '#032957'}}>
                            <h5><b>{quoteData.name}</b></h5>
                            <span style={{borderBottom: '1px rgba(3, 41, 87, 0.2) solid'}}
                                   className='color-secondary px-4 pb-3'>{quoteData.position}</span>
                            <h4 className='pt-3 px-5 mobile-px-3 mw-800px' style={{fontWeight: '600', lineHeight: '45px'}}>
                                {quoteData.text}
                            </h4>
                        </div>
                </div>
            </div>
            {Array.isArray(projects) &&
                <div className="py-6 mobile-py-5">
                    <div className="container text-center">
                        <div className="mb-5 center mw-600px">
                            <h3 className="font-bold">Support Projects That Fight<br />Climate Change</h3>
                            <p>CarbonClick supports only the highest quality carbon offsetting projects around the world, to bring you peace of mind and results.</p>
                        </div>
                        <div className="text-align-start mobile-text-center">
                            <ProjectsList title={null} projects={projects} />
                        </div>
                    </div>
                </div>
            }
            <div className="py-6 mobile-p-0">
                <div className="container text-center">
                    <h3 className="font-bold mb-4">Climate Friendly Traveller FAQs</h3>
                    <div className="mb-3 center text-align-start mw-800px">
                        <Accordian title={"What does carbon offsetting do?"} >Carbon offsetting is a tool that allows individuals or companies to reduce their environmental impact. Purchasing carbon offsets supports environmental projects that remove greenhouse gases from the air, these projects could be things like reforestation projects or investment into renewable energy. Purchasing one carbon offset credit removes the equivalent of one tonne of CO<sub>2</sub> from the atmosphere.</Accordian>
                        <Accordian title={"What is a carbon offset?"} >A carbon credit is what is bought and sold when dealing with voluntary carbon offsets. For every tonne of CO<sub>2</sub> that a project manages to absorb or otherwise reduce, a carbon credit is issued. Carbon credits are independently certified by international standards and held in registries like the UK Land Carbon Registry (for Woodland Carbon Code projects) or the Gold Standard Registry (for international projects).</Accordian>
                        <Accordian title={"How are flight emissions calculated?"}>Emissions are calculated based on the individual route flown, taking into account the distance travelled as well as the type of aircraft, seating configuration, aircraft age, and typical load factors on your particular route. CarbonClick’s approach to calculating emissions from your flight has been approved by the UK Government.</Accordian>
                        <Accordian title={"What about radiative forcing?"}>Due to the ongoing scientific debate and significant uncertainty about the effects of non-CO<sub>2</sub> emissions,    CarbonClick’s calculations do not currently include the effects of radiative forcing.  This is consistent with the policy approach of the UK Government.</Accordian>
                        <Accordian title={"Who are CarbonClick?"} >CarbonClick are a New Zealand-based company that exists to reverse climate change. CarbonClick empowers businesses and their customers to tackle climate change, by making carbon offsets simple, trustworthy and cost effective, building a happier and more sustainable future for everyone.</Accordian>
                        <Accordian title={"Where does the money go?"} >The money paid to CarbonClick for carbon credits goes towards certified carbon offset projects that have met the highest standards. The funds are distributed amongst carbon offset suppliers carrying out the projects and CarbonClick takes a margin on the carbon credits for sourcing projects, quality assurance, administration and transaction fees.</Accordian>
                        <Accordian title={"How do I know the offset is trustworthy?"} >CarbonClick is committed to making carbon offsetting safe and trustworthy, we do this by tracing every transaction to the projects it supported so you can see the retirement certificates for every kg of CO<sub>2</sub> offset. The trace link is sent in every purchase confirmation email, and can also be found on our website.</Accordian>
                        <Accordian title={"Is my airline already offsetting my flight?"} >Carbon offsets deliver environmental improvement, you can’t have too much of a good thing! However, we believe our customers should make informed choices and be aware that some airlines automatically offset emissions from their flights. For example, British Airways offset emissions from domestic flights in the UK and EasyJet offset emissions from all flights. Additionally, JetBlue, Loganair and Jet2.com have recently introduced offsetting for emissions not part of compliance schemes. Further information will be available from your airline.</Accordian>
                    </div>
                    <a href="https://www.carbonclick.com/faq/" target="_blank" rel="noopener noreferrer" className="no-color-btn btn-primary btn-lg font-bold" >View All</a>
                    <div className="mobile-show py-5"></div>
                </div>
            </div>
            <footer className="w-100 img-cover footer-img py-6 bg-grey" >
                <div className="container color-white py-5">
                    <p><b>Powered by</b></p>
                    <div className="d-flex">
                        <div>
                            <a href={footerLinks?.carbonclick} target="_blank" rel="noopener noreferrer">
                                <img style={{ height: 30 }} className="footer-logo" src={logo} alt="carbonclick" />
                            </a>
                        </div>
                        <div className="right">
                            <div>
                                <a href={footerLinks?.facebook} target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: 30 }} src={facebook} alt="carbonclick" />
                                </a>
                                <a href={footerLinks?.instagram} target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: 30 }} src={instagram} alt="carbonclick" />
                                </a>
                                <a href={footerLinks?.twitter} target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: 30 }} src={twitter} alt="carbonclick" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr className="mb-4" />

                    <div className="row">
                        <div className="col-4">
                            <div>                                
                                <div className="mb-3"><a className="color-white" href={footerLinks?.aboutCarbonClick} target="_blank" rel="noopener noreferrer">About CarbonClick</a></div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.faq} target="_blank" rel="noopener noreferrer">FAQs</a></div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></div>
                                <div className="mb-3"><a className="color-white" href={footerLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div>                                
                                <div className="mb-3"><a className="color-white" href="mailto:hello@carbonclick.com" target="_blank" rel="noopener noreferrer">hello@carbonclick.com</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </main>
    )
}

//IMPORTANT - THIS IS USED BY EMA AND STN TOO
export default MagEmaStnLandingPage;