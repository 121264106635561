import React from 'react';
import './WideImpactCounter.css'
import './odometer-theme-default.css'
import Odometer from 'react-odometerjs';
const WideImpactCounter = ({number, title, subText, horizontal, Compressed}) => (
    <section className={`${Compressed === 'true' ? 'small-text flex-grow' : horizontal === 'true' ? 'small-text flex-grow' : 'col-12'} p-2 d-grid`}>
        <div className="wide-impact-counter impact-counter">
            <div className="counter-numbers">
                <Odometer value={number || "..."} format="(,ddd)" animation='count' />
            </div>
            <div className="counter-title">{title}</div>
            {subText && <div className="font-italics">{subText}</div>}
        </div>
    </section>
);

export default WideImpactCounter