// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../radiance/media/Radiance-Logo.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.radiance-style .color-primary{\n    color: #A88F6E;\n}\n\n.radiance-style .background-color-primary{\n    background-color: #A88F6E;\n}\n\n.radiance-style .logo-image {\n     background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.radiance-style .impact-footer{\n    background-color: #A88F6E;\n}\n\n.radiance-style .wide-impact-counter {\n    border: 1px solid #E5E5E5;\n    background-color: #FBFBF7;\n}\n\n", "",{"version":3,"sources":["webpack://src/common/components/impact/merchant-config/radiance.css"],"names":[],"mappings":";AACA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;KACK,yDAAqE;AAC1E;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":["\n.radiance-style .color-primary{\n    color: #A88F6E;\n}\n\n.radiance-style .background-color-primary{\n    background-color: #A88F6E;\n}\n\n.radiance-style .logo-image {\n     background-image: url('../../../../radiance/media/Radiance-Logo.png');\n}\n\n.radiance-style .impact-footer{\n    background-color: #A88F6E;\n}\n\n.radiance-style .wide-impact-counter {\n    border: 1px solid #E5E5E5;\n    background-color: #FBFBF7;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
