
export const MERCHANT_BASKET = 'merchant_basket';
export const getConfigByMerchantCode = (merchantCode) =>{
    const config = merchantConfigs.find(c=>c.code===merchantCode);
    return config ? config : defaultConfig;
}

const defaultConfig={
    code:'',
    colorPrimary: '2AA43C',
    stats: ['co2Quantity', 'customers', 'trees'],
    topLevelClassNames:'default-dashboard-style',
    locale: "en_nz"
}

const merchantConfigs=[
    {
        code:'AASMARTFUEL', 
        topLevelClassNames:'aasmartfuel-style',
        colorPrimary:'FFD400',
        stats: ['distance', 'co2Quantity', 'drivers', 'swipes'],
        locale: "en_nz",
        showProjects: [MERCHANT_BASKET],
    },
    {
        code:'SH_HOTELS', 
        topLevelClassNames:'one-hotels-style',
        colorPrimary:'485532',
        stats: ['nights', 'co2Quantity', 'travellers', 'trees'],
        locale: "en_nz",
    },
    {
        code:'BES_NZ_PITA_PIT', 
        topLevelClassNames:'pitapit-style',
        colorPrimary:'2EAF4D',
        stats: ['lunches_progress', 'co2Quantity_progress', 'trees_progress'],
        locale: "en_nz"
    },
    {
        code:'AIRTAHITINUI_TN', 
        topLevelClassNames:'airTahitiNui-style',
        colorPrimary:'2DCCD3',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        locale: "en_pf"
    },
    {
        code:'ETIHAD_EY', 
        topLevelClassNames:'etihad-style',
        colorPrimary:'#E7A722',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        locale: "en_ae"
    },
    {
        code:'HIA_DOHA', 
        topLevelClassNames:'doha-style',
        colorPrimary:'#293D3E',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        locale: "en_ae",
    },
    {
        code:'MYC_PW_SAPPHIRE_TRAVELLER', 
        topLevelClassNames:'sapphire-style',
        colorPrimary:'#2E3DC2',
        stats: ['nauticalMiles', 'boaties', 'co2Quantity', 'trees'],
        locale: "en_ae",
    },
    {
        code:'MYC_NZ_RADIANCE', 
        topLevelClassNames:'radiance-style',
        colorPrimary:'#A88F6E',
        stats: ['co2Quantity_progress', 'products_progress', 'trees_progress'],
        locale: "en_ae"
    },
    {
        code:'BES_US_INCLOVER', 
        stats: ['co2Quantity_progress', 'products_progress', 'trees_progress'],
        colorPrimary: '#43B02A',
        locale: "en_nz"
    },
    {
        code:'ROUND_THEORY', 
        topLevelClassNames:'round-theory-style',
        colorPrimary:'#96D096',
        stats: ['co2Quantity_progress', 'wine_progress', 'trees_progress'],
        locale: "en_ae"
    },
    {
        code:'BES_NZ_GOOD_GRAPE', 
        stats: ['co2Quantity_progress', 'wine_progress', 'trees_progress'],
        locale: "en_nz"
    },
    {
        code:'BES_NZ_TOMIMPACTPROGRESSTEST', 
        topLevelClassNames:'default-dashboard-style',
        stats: ['co2Quantity_progress', 'trees_progress'],
        locale: "en_gb"
    },
    {
        code:'ALL',
        colorPrimary: '2AA43C',
        stats: ['co2Quantity', 'transactions', 'trees', 'merchants'],
        topLevelClassNames:'default-dashboard-style',
        locale: "en_nz"
    },
    {
        code:'BES_GB_ST_JAMES_QUARTER',
        colorPrimary: '#DA89C6',
        stats: ['shoppers', 'trees', 'co2Quantity'],
        topLevelClassNames:'st-james-style',
        locale: "en_nz"
    },
    {
        code:'BES_NZ_VOLUNTEER_HQ',
        colorPrimary: '#2A8644',
        stats: ['co2Quantity', 'customers', 'trees'],
        topLevelClassNames:'volunteer-hq-style',
        locale: "en_nz"
    },
    {
        code:'BES_NZ_INTERN_ABROAD_HQ',
        colorPrimary: '#F05F3C',
        stats: ['co2Quantity', 'customers', 'trees'],
        topLevelClassNames:'intern-abroad-hq-style',
        locale: "en_nz"
    },
    {
        code: "AIRPORT_MAG",
        colorPrimary: '#D40E6E',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        topLevelClassNames:'air-mag-style',
        locale: 'en_gb'
    },
    {
        code: "BES_NZ_CONTACT_ENERGY",
        stats: ['transactions', 'co2Quantity', 'kwh_nz', 'trees'],
        topLevelClassNames:'contact-energy-style',
        locale: 'en_nz'
    },
    {
        code: "AIRPORT_LIM",
        colorPrimary: '#00B2EE',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        topLevelClassNames:'air-mag-style',
        locale: 'en_sp'
    },
    {
        code: "AIRPORT_GPS",
        topLevelClassNames:'default-dashboard-style',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        locale: 'en_sp'
    },
    {
        code: "AMADEUS_GLOBAL",
        topLevelClassNames:'default-dashboard-style',
        stats: ['airDistance', 'travellers', 'co2Quantity', 'trees'],
        locale: 'en_nz'
    },
    {
        code: 'BES_NZ_CHILDFUND',
        colorPrimary: '2AA43C',
        topLevelClassNames:'default-dashboard-style',
        stats: ['co2Quantity', 'customers', 'trees', 'communityContributions'],
        locale: 'en_nz',
    }
]