import React, { useEffect } from 'react';
import '../../common/css/global.css'
import '../../common/css/ImpactPage.css'
import ImpactDashboard from "../../common/components/impact/ImpactDashboard";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    trackAdditionalPageView
} from "../../common/util/ccAnalyticsV2";

const ImpactPage = ({merchantCode}) => {

    useEffect(() => {
        //trackAdditionalPageView('/impact',['aviation','customer'])
    }, []);

    return (
        <>
            <ImpactDashboard merchantCode={merchantCode} />
        </>
    )
};


export default ImpactPage;
