// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../media/cc-icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".default-dashboard-style .color-primary{\n    color: #2AA43C;\n}\n\n.default-dashboard-style .background-color-primary{\n    background-color: #2AA43C;\n}\n\n.default-dashboard-style .wide-impact-counter{\n    background-color: #2AA43C;\n    border-radius: 4px;\n}\n\n.live-impacts{\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.default-dashboard-style .logo-image {\n     background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n", "",{"version":3,"sources":["webpack://src/common/components/impact/merchant-config/default-dashboard-impact.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;KACK,yDAAmD;AACxD","sourcesContent":[".default-dashboard-style .color-primary{\n    color: #2AA43C;\n}\n\n.default-dashboard-style .background-color-primary{\n    background-color: #2AA43C;\n}\n\n.default-dashboard-style .wide-impact-counter{\n    background-color: #2AA43C;\n    border-radius: 4px;\n}\n\n.live-impacts{\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.default-dashboard-style .logo-image {\n     background-image: url('../../../media/cc-icon.svg');\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
